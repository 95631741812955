module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.3_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Patrick Sletvold's Blog","short_name":"Patrick's Blog","start_url":"/","background_color":"#ffffff","theme_color":"#d57d10","display":"minimal-ui","icon":"static/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-webmention@0.2.0_patch_hash=k2evzlrbmmyhpqiecdtsun7cwq_react@18.2.0/node_modules/gatsby-plugin-webmention/gatsby-browser.js'),
      options: {"plugins":[],"username":"patricksletvold.com","mentions":true,"pingbacks":true,"domain":"patricksletvold.com","fetchLimit":10000,"token":"Yt3FmRjgN2p9eF-EZKn66Q"},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-plausible@0.0.7_gatsby@5.13.3_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"patricksletvold.com","customDomain":"s.patricksletvold.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.13.3_babel-eslint@10.1.0_esbuild@0.14.51_react-dom@18.2.0_react@18.2.0_typescript@5.3.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
